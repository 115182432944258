// Your variable overrides
$blue: #047BBB;
$green: #2B651B;
$body-color: #464749;
$font-size-base: 1rem;
$font-size-lg: $font-size-base;
$container-max-widths: (
  sm: 540px, 
  md: 720px,
  lg: 960px,
  xl: 1340px
) !default;
$font-family-sans-serif: Arial, Roboto, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-weight-light: 300;
$font-weight-normal: 300;
$font-weight-bold: 300;
$headings-font-weight: 300; 
$input-btn-padding-y: .5rem;
$input-btn-padding-x: 1rem !default;
$input-btn-padding-y-lg: .5rem;
$input-btn-padding-x-lg: 3.25rem !default;
$border-radius:               .25rem !default;
$border-radius-sm:            .175rem !default;
$btn-border-radius:           $border-radius-sm !default;
$btn-border-radius-lg:           $border-radius-sm !default;
$dark:          #424242;
$nav-pills-border-radius: $border-radius-sm !default;
$nav-link-padding-y:                1.25rem !default;
// Bootstrap and its default variables
@import "../bootstrap/scss/bootstrap";

//Custom styles
body {
  letter-spacing: 0;
  lin-height: 140% !important;
}
b, strong {
  font-weight: bold !important;
}
.navbar-betaseed {
  background: #2b651b;
  height: 50px;
  margin: 40px 0 0 0;
  padding: 0 3rem;
}
.navbar-betaseed .navbar-brand {
  background: #ffffff;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0 4px rgba(0,0,0,.2);
  display: block;
  height: 136px;
  width: 140px;
  position: relative;
  z-index: 999;
  transition: all .25s ease-in-out;
}
.navbar-betaseed .navbar-brand:hover {
  box-shadow: 0 0 5px rgba(0,0,0,.4);
  transform: scale(1.1);
}
.navbar-betaseed .navbar-nav {
  margin: 0 0 0 0;
}
.navbar-betaseed .navbar-brand img {
  display: block; 
  height: 85px !important;
  margin: 15px 15px 15px 15px;
  width: 110px !important;
}
.navbar-betaseed .nav-link {
  color: #ffffff;
  display: block;
  font-size: 14px;
  padding: 15px 18px 14px 18px !important;
  text-transform: uppercase;
  transition: all .25s ease-in-out;
}
.navbar-betaseed .active .nav-link {
  color: #2b651b;  
  background: #ffffff;
}
.navbar-betaseed .nav-link:hover {
  color: #ffffff;  
  background: #578449;
}
.jumbotron-fluid.jumbotron-header {
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding: 0 0 0 0;
  position: relative;
}
.jumbotron-fluid.jumbotron-header .img-fluid {
  width: 100%;
}
.jumbotron-fluid.jumbotron-header .jumbotron-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 100px auto 0 auto;
  width: 960px; 
  height: auto;
}
.jumbotron-fluid.jumbotron-header .jumbotron-content h1 {
  color: #ffffff;
  font-size: 48px;
  line-height: 60px;
  text-transform: none;
}
.container-pure-stand {
  margin-top: -7rem;
}
.container-pure-stand.container-products {
  margin-top: -14rem;
}


.card-title {
  font-size: 24px;
  color: #2B651B !important;
}
.container-products .card-body {
  flex: 1 1 auto;
  padding: 0 2rem 2rem 2rem;
}
.container-products .card {
  transition: all .15s ease-in-out;
  box-shadow: 2px 4px 10px 0 rgba(0,0,0,0.1) !important;  
}
.container-products .card:hover {
  transform: scale(1.03);
  box-shadow: 2px 4px 30px 0 rgba(0,0,0,0.1) !important;
}

.container-news-cards .card-body { 
  flex: 1 1 auto;
  padding: 0 0 0 0;
}
a.card .card-body * {
  color: #464749;
  text-decoration: none;
}
a.card,
a.card:hover {
  text-decoration: none !important;
}
.card div.a-label {
  width: 70px;
  height: 70px;
  background: #ededed;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  line-height: 110%;
  padding: 12px 4px;
  color: #616161;
  transform: rotate(30deg);
  letter-spacing: -0.005em;
    z-index: 9;
}
.card div.a-label.style-1,
.container-product .card div.a-label.style-1 {
  color: #421D00;
  background: rgb(255,220,0);
  background: -moz-linear-gradient(top, rgba(255,220,0,1) 60%, rgba(255,255,255,1) 60%, rgba(255,255,255,1) 65%, rgba(66,29,0,1) 65%, rgba(66,29,0,1) 100%);
  background: -webkit-linear-gradient(top, rgba(255,220,0,1) 60%,rgba(255,255,255,1) 60%,rgba(255,255,255,1) 65%,rgba(66,29,0,1) 65%,rgba(66,29,0,1) 100%);
  background: linear-gradient(to bottom, rgba(255,220,0,1) 60%,rgba(255,255,255,1) 60%,rgba(255,255,255,1) 65%,rgba(66,29,0,1) 65%,rgba(66,29,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffdc00', endColorstr='#421d00',GradientType=0 );
}
.container-product .card div.a-label {
  width: 100px;
  height: 100px;
  background: #ededed;
  position: absolute;
  top: -20px;
  right: -20px;
  border-radius: 50%;
  text-align: center;
  font-size: 26px;
  line-height: 110%;
  padding: 24px 4px;
  color: #616161;
  transform: rotate(30deg);
  letter-spacing: -0.005em;
}

img.a-label.bubble {
  display: block;
  width: 120px;
  height: 120px;
  background: #ffffff;
  position: absolute;
  top: -30px;
  right: -10px;
  border-radius: 50%;
  padding: 4px;
}


.card-text ul {
  padding: 0 !important;
  margin: 1rem 0 0 1.75em !important;
  list-style: none !important;
}
.card-text ul > li {
  margin-bottom: 1em;
}
.card-text ul > li:before {
  display: block;
  margin: .15em 0 0 -1.75em;
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  background: url(../images/icons/check-yellow.svg) center center / 14px auto no-repeat;
}
.btn {
  min-width: 200px;
  transition: all .15s ease-in-out;
  font-size: 14px;
}
h1, h2, h3 {
  text-transform: uppercase;
}
h2 {
  font-size: 36px;
  color: #421D00;
  margin-bottom: 0;
}
h2.pb-3 {
  padding-bottom: .15em !important;
  margin-bottom: 0 !important;
}
.card h1, .card h2, .card h3 {
  text-transform: none;
}
.card h3 {
  font-size: 18px;
}
h5 {
  font-size: 24px;
}
.card-text ul {
  font-size: 14px;
}

p strong {
  font-weight: bold;
}

small, .small {
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
}

.container-article :not(.card-text) ul:not(.navbar-nav) {
  list-style: none;
  padding-left: 1.5em;
}
.container-article ul:not(.navbar-nav) {
  margin: .5em 0 1em 0;
}
.container-article ul ul {
  margin: .5em 0 0 0;
}
.container-article ul:not(.navbar-nav) li {
  font-size: 16px;
  line-height:24px;
  margin-bottom: .5em;
}
.container-article li::before {
  content: "\2022";
  color: #FFDC00;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}



footer { display: block; }
footer > .container:first-child > .row {
  padding: 90px 0;
}  
footer .btn {
  padding: .625rem 2.95rem !important;
  min-width: 100px;
}
footer .divider {
  border-top: 1px solid #797979;
  margin: 0 -15px;
}
footer .meta {
  padding: 1rem 1rem;
  font-size: 12px; 
  letter-spacing: .1em; 
}
footer .meta a {
  color: #fff;
  text-decoration: underline;

}
footer > .container:first-child > .row > div.col-md-3 {
  flex: 0 0 16.66667% !important;
  max-width: 16.66667% !important;
}
footer > .container:first-child > .row > div.col-md-3 img {
  width: 140px;
  height: 140px;
}
footer .meta .col-6.text-center .copyright,
footer .meta .col-6.text-center a {
  text-decoration: none;
  padding: 0 3px;
  color: #ffffff;
}
footer .meta .col-6.text-center a:hover {
  text-decoration: underline;
}
footer .meta .col-3.text-right a {
  display: inline-block;
  background: #ffffff;
  width: 32px;
  height: 32px;
  text-align: center;
  padding-top: 5px;
  color: #424242;
  border-radius: 50%;
  font-size: 16px;
  margin-top: -6px;
  margin-bottom: -7px;  
}
footer .meta .col-3.text-right a i {
  margin-left: 2px;
}
footer ul {
  padding: 0;
  margin: 1.75rem 0 2.75rem 1.75em;
  list-style: none;
}
footer ul > li {
  margin-bottom: .5em;
}
footer ul > li:before {
  display: block;
  margin: .15em 0 0 -1.75em;
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  background: url(../images/icons/check-yellow.svg) center center/14px auto no-repeat;
}
footer .col-md-6 {
  position: relative;
  padding-left: 90px !important;
}
footer .col-md-6 img {
  position: absolute;
  top: 5px;
  left: 15px;
  width: 50px;
  height: auto;
}
footer .col-md-6 form.newsletter {}
footer .col-md-6 form.newsletter .col {
  padding-right: 0 !important;
}
footer > .container:first-child > .row > div.col-md-9 img {
  width: 45px;
  height: 45px;
}
.nav.nav-tabs {
  border-bottom: 0;

}
.nav.nav-tabs .nav-item.nav-link {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fdfdfd+0,ffffff+8,ffffff+50,ffffff+80,f1f1f1+100 */
  background: #fdfdfd; /* Old browsers */
  background: -moz-linear-gradient(top, #fdfdfd 0%, #ffffff 8%, #ffffff 50%, #ffffff 80%, #f1f1f1 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #fdfdfd 0%,#ffffff 8%,#ffffff 50%,#ffffff 80%,#f1f1f1 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #fdfdfd 0%,#ffffff 8%,#ffffff 50%,#ffffff 80%,#f1f1f1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fdfdfd', endColorstr='#f1f1f1',GradientType=0 ); /* IE6-9 */
  border: 1px solid #D8D8D8;  
  color: #424242;
  font-size: 18px;
  transition: all .25s ease-in-out;
}
.nav.nav-tabs .nav-item.nav-link.active {
  background: #2B651B;
  color: #ffffff;
  font-size: 18px;
}

.nav.nav-tabs .nav-item.nav-link:not(.active):hover {
  background: #E2EBE1;
}

.tab-content {
  border: 1px solid #D8D8D8;
  border-top: 0;
  border-radius: 0 0 
}

.read-more {
  color: #202020;
  padding-left: 23px;
  background: url(../images/icons/arrow-blue.svg) left 1px / 8px auto no-repeat;
  transition: all .15s ease-in-out;  
}
.read-more:hover {
  text-decoration: none;
  background: url(../images/icons/arrow-blue.svg) 4px 1px / 8px auto no-repeat;
}
#calculators,
.primary-gradient {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#31761e+0,2b651b+100 */
  background: #31761e; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #31761e 0%, #2b651b 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #31761e 0%,#2b651b 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #31761e 0%,#2b651b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#31761e', endColorstr='#2b651b',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.container-betapoint a:hover,
.container-competence-cards a:hover {
  text-decoration: none;
}
.container-betapoint .card {
  box-shadow: 0 2px 15px 0 rgba(0,0,0,0.1) !important;
}
.container-competence-cards .col-md-4.mb-5 {
  margin-bottom: 2rem !important;
}
.container-competence-cards .card-text {
  text-align: center;
  font-size: 18px;
  color: #424242 !important;
}
.container-betapoint .card .card-text {
  text-align: center;  
  font-size: 24px;
  line-height: 120%;
  color: #424242;
}
.container-betapoint a:hover,
.container-competence-cards a:hover {
  text-decoration: none;
}

.container-contact {
  font-size: 16px;
  position: relative;
  background-color: #ededed;
}
.container-contact:after {
  content: "";
  display: table;
  clear: both;
}
.container-contact a {
  text-decoration: none;
}
.text-z-index h3 {
  text-transform: none !important;
}
.slide-wrapper {
  position: relative;
  background: #31761e;
  width: 27vw;
  z-index: 20;
}
.container-contact ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.container-contact ul a {
  display: block;
  padding: 40px;
}
.level-regions {
  z-index: 11;
  background: #31761e;
  padding-bottom: 30px;
}
.level-locations {
  position: absolute;
  left: -2px;
  top:0;
  width: 27vw;
  height: 100%;
  transition: all .25s ease-in-out;
  z-index: -1;
  background: #ffffff;
}
.level-contacts {
  position: absolute;
  left: -2px;
  top:0;
  width: 27vw;
  transition: all .25s ease-in-out;
  background: #ffffff;
  z-index: -1;
}

.level-locations,
.level-contacts {
  height: 100%;
}
.level-regions > li.active > .level-locations {
  display: block;
  left: 27vw;
  z-index: -1;
}
.level-locations > li.active > .level-contacts {
  display: block;
  left: 27vw;
  z-index: -2;
}
.level-regions > li {
  background: #31761e;
  border-bottom: 2px solid #4B823C;
}
.level-regions > li:first-child {
  border-top: 2px solid #4B823C;
}
.level-regions > li > a {
  color: #ffffff;
  font-size: 16px;
}
.level-regions > li > a:hover,
.level-regions > li.active,
.level-regions > li.active > a {
  background: #296418;
}
.level-locations a,
.level-contacts {
  color: #424242;
}
.level-contacts > li {
  border-bottom: 1px solid #ededed;
}
.level-locations > li {
  background: #ffffff;
  border-bottom: 1px solid #ededed;
}
.level-locations > li > a:hover,
.level-locations > li.active > a {
  background: #f0f0f0;
}
.text-z-index {
  position: relative;
  z-index: 20;
 /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#31761e+0,2b651b+100 */
  background: #31761e;
}
.modal-dialog {
  max-width: 80vw;
  margin: 3.75rem auto;
}
.modal-header {
  position: relative;
}

.modal-header .close {
  position: absolute;
  padding: 1rem;
  margin: -4rem -1rem 0 0;
  right: 0;
}
.modal-header .img-fluid {
  min-width: 100%;
  height: auto;
  -webkit-border-top-right-radius: 5px !important;
  -webkit-border-top-left-radius: 5px !important;
  -moz-border-radius-topright: 5px !important;
  -moz-border-radius-topleft: 5px !important;
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
}
.modal-content .figure-caption {
  color: #A3A3A3;
  font-size: 12px;
}
.close { opacity: 1; }
.close-modal {
  font-size: 40px;
  opacity: 1 !important;
  text-shadow: 0 0 0 rgba(0,0,0,0);
}

.jumbotron-product {
  padding: 3.5rem 0 1.5rem 0;
}
.jumbotron-product h1 {
  font-size: 48px;
  padding-bottom: 0rem !important;
}

.jumbotron-product, .jumbotron-login {
  background: transparent;
}
.jumbotron-product h1 {
  text-transform: none;
  margin-bottom: 0;
}
.container-product {
  margin-bottom: 4rem !important;
}
.container-product .row {
  margin-left: 0;
  margin-right: 0;
}
.container-product .card {
  padding-bottom: 0 !important;
  border: none;
  box-shadow: 2px 4px 20px 0 rgba(190,190,190,0.8);
}
.container-product h2.card-title {
   font-size: 36px;
   font-weight: bold;
 }
.container-product .headlines {
   min-height: 100px;
}
.container-product h3 {
  color: #421D00;
  font-size: 32px;
  font-weight: normal;
}
.container-product b {
  font-weight: bold;
}
.container-product .card-body.block-3 {
  padding-top: 138px;
  padding-bottom: 40px;
}
.container-product h4 {
  font-size: 16px;
  font-weight: bold;
}
.container-product .card-body.block-3 h4 {
  margin-bottom: 1em;
}
.container-product .card-text ul {
  font-size: 16px;
}
.container-product .links {
  margin-right: -1rem;
}
.container-product .links a.btn {
  border-radius: 0;
  margin-left: -3px;
}
.container-characters {
  background: #F3F3F3;
}
.media-element { margin: 1em 0 }
.media-element > * {
  display: block;
  box-shadow: 0 0 9px rgba(0,0,0,.2);
}
.media-captions {
  padding: 12px;
  font-size: 12px;
  color: #9B9B9B;
  background: #F3F3F3;
}
.container-customer-quote .customer-name {
  font-size: 12px;
}

.container-quicklink-tiles a.card {
  padding-top: 3rem;
  padding-bottom: 1rem;
  box-shadow: 2px 4px 30px 0 rgba(190,190,190,0.5) !important;  
}
.container-quicklink-tiles a.card  h5 {
  color: #421D06 !important;
  font-size: 24px !important;
}
.container-quicklink-tiles a.card .card-text .btn {
  margin-top: 18px;
  min-width: auto;
  padding: 0.5rem 1.5rem;
}
a span.btn { color: #fff !important; }


body.us .container-pure-stand {
  margin-top: -10rem;
}
.card-title.ci-color-2,
body.us h2 {
  color: #421D00;
}

.btn-xlg {
  padding: 2rem 6.25rem;
}
.container-regions-teaser .jumbotron-content {
  position: relative;
}
.container-teaser .jumbotron-content.text-center {
  margin-top: 0 !important;
  top: calc(50% - 60px) !important;
}

.container-wiki h5 {
  font-size: 18px;
  color: #424242 !important;
  font-weight: bold;
}

.container-wiki #accordion {
  border-radius: 0.25rem;
  border: 1px solid #D8D8D8;
  background: #ffffff;
}
.container-wiki #accordion .card {
  border-radius: 0;  
  border: 0;
  border-top: 1px solid #D8D8D8;
  background-color: transparent;
}
.container-wiki #accordion .card:first-child {
  border-radius: 0;
  border: 0;
  border-top: 0;
}
.container-wiki .card a.badge.badge-pill.badge-primary {
  color: #9B9B9B !important;
  font-size: 12px !important;
  background: none !important;
  padding: 0 !important;
}
.container-wiki #accordion .card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.0);
  border-bottom: 0;
  border-radius: 0;  
  background-color: transparent;
  
  background-image: url(../images/icons/arrow-down-blue.svg) !important;
  background-position: right 19px center !important;
  background-repeat: no-repeat !important;
  background-size: 19px auto !important;
}
.container-wiki #accordion .card-header.show {
  background-image: url(../images/icons/arrow-up-blue.svg) !important;
}
.container-wiki #accordion .card-header a {
  display: block;
  padding: 20px 19px;
  cursor: pointer;
}
.container-wiki #accordion .card .list-group {
  padding: 3px 0 24px 0;
}
.container-wiki #accordion .card .list-group-item,
.container-wiki #accordion .card .list-group-item:first-child,
.container-wiki #accordion .card .list-group-item:last-child {
  border-radius: 0;
  border: 0;
  padding: 0;
}
.container-wiki #accordion .card .list-group-item a {
  color: #333333;
  display: block;
  padding: 8px 19px;
}
.container-wiki #accordion .card .list-group-item a.active,
.container-wiki #accordion .card .list-group-item a:hover {
  color: #333333;
  text-decoration: none;
  background-color: #f6f7fa;
}
.container-wiki .col-md-9 {
  padding-left: 0 !important;
}
.container-wiki .col-md-9 > .container {
}
.container-wiki .col-md-9 h5 {
  margin-top: 9px !important;
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
}
.container-wiki .col-md-9 .card-body {
  padding-top: 12px;
}
.container-wiki .col-md-9 .card-text {
  font-size: 14px;
}
.container-wiki .col-md-9 .card {
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2) !important;
}  
body .card.shadow-none {
  box-shadow: none !important;
}

.card-teaser .card-body {
  padding: 0;
}
.card-teaser .card-body h5 {
  font-size: 18px;
  color: #000000;
}
h3.card-title {
  color: #000000 !important;
}
.card-teaser .card-body .btn {
  margin-top: 1rem;
}
.jumbotron-fluid.jumbotron-header.jumbotron-article .jumbotron-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 68px auto 0 auto;
    width: 100%; 
    height: auto;
}
.btn-auto {
  min-width: auto;
}
.jumbotron .backlink {
  position: absolute;
  margin-top: 3.5rem;
  padding-left: 2.55em;
  padding-right: 2em;
  background-image: url(../images/icons/arrow-left-white.svg);
  background-position: 14px center;
  background-size: 8px auto;
  background-repeat: no-repeat;
}
.jumbotron-product .backlink {
  position: absolute;
  margin-top: .5rem;
  z-index: 2;
}

.container.container-article .jumbotron {
  background-color: transparent !important;
}
.clear {
  clear: both !important;
  float: none !important;
}
.cust-gallery .img-fluid {
  margin-right: 2.25em !important;
}
.cust-gallery .img-fluid:nth-child(even) {
  margin-right: 0 !important;
}

.img-fluid img {
  display: block;
}
.figure-caption {
  display: block;
  margin-top: 6px;
  color: #A3A3A3;
}
.container.container-article {
  color: #424242;
}
.container.container-article h2 {
  color: #421D00 !important;
  font-size: 36px !important;
  line-height: 120%;
}
.container.container-article h3 {
  color: #424242 !important;
  font-size: 32px !important;
}
.container.container-article h4 {
  color: #424242 !important;
  font-size: 16px !important;
  font-weight: bold !important;
  margin-bottom: .25em !important;
}
.container.container-article .col-sm-8 {
  max-width: 866px;
}
.container.container-article-type-3 p,
.container.container-article-type-2 p {
  text-align: justify;
}
.container.container-article .figure-caption {
  font-size: 12px !important;
}
.container-betapoint-steps-full {
  background-size: 100% auto;
  
}
.container-betapoint-steps-half .col-sm-6 {
  padding-left: 0;
  padding-right: 0;
}
.container-full > .row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.container-full.container-betapoint-steps-full {
  background: url(http://betaseed.dbidev.de/images/devices.svg) rgb(43,101,27);
  background: url(http://betaseed.dbidev.de/images/devices.svg) -moz-linear-gradient(left, rgba(43,101,27,1) 70%, rgba(49,119,30,1) 100%);
  background: url(http://betaseed.dbidev.de/images/devices.svg) -webkit-linear-gradient(left, rgba(43,101,27,1) 70%,rgba(49,119,30,1) 100%);
  background: url(http://betaseed.dbidev.de/images/devices.svg) linear-gradient(to right, rgba(43,101,27,1) 70%,rgba(49,119,30,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2b651b', endColorstr='#31771e',GradientType=1 );
  background-size: auto 70%;
  background-position: right -60px center;  
  background-repeat: no-repeat;
}
.container-full.container-betapoint-steps-full .text-white.py-5 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}
.container-full.container-betapoint-steps-full .text-white img {
  width: 150px;
  height: auto;
  margin-right: 25px;
}
.container-full.container-betapoint-steps-full .btn.btn-primary {
  color: #424242;
  background-color: #ffffff;
  border-color: #ffffff;
  margin-bottom: 40px;
}
.container.container-betapoint-steps-half p.lead {
  font-size: 16px !important;
  margin-bottom: 32px;
}
.container.container-betapoint-steps-half h2 {
  font-size: 32px;
  text-transform: none;
}
.container.container-betapoint-steps-half h3 {
  font-size: 16px;
  font-weight: bold;
  text-transform: none;
  margin-bottom: 4px
}

.container-betapoint-steps-half .text {
  margin: 4em;
  margin-bottom: 0;
}
.container-betapoint-steps-half .text .row {
  margin: 0 0 0 0;
}
.container-betapoint-steps-half ul {
  list-style: none;
  margin: 22px 0 0 0;
  padding: 0;
  font-size: 16px;
}
.container-betapoint-steps-half ul li {
  margin-bottom: 6px;
}
.container-lead .lead {
  font-size: 24px !important;
}
.container-bonus h2 {
  font-size: 36px;
  text-transform: uppercase;
  color: #421D00;
}
.container-bonus .col-sm-3 .card {
  box-shadow: 2px 4px 10px 0 rgba(0,0,0,0.1) !important;  
}
.container-video-big .row {
  margin-left: 0;
  margin-right: 0;
}  
.container-video-big h2 {
  font-size: 36px;
  text-transform: uppercase;
  color: #421D00;
}

.container-full {
  background-size: 100% auto;
}
.container-video-big .jumbotron.jumbotron-header {
  margin: 0 !important;
  padding: 0 !important;  
}

.container-contact-eyecatcher {
  background-color: #EEEEEE;
}
.container-contact-eyecatcher img {
  width: 30px;
  margin-bottom: .15em;
}
.container-contact-eyecatcher img.img-mobile {
  width: 20px;
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  -webkit-transform: rotate(30deg);
}
.container-contact-eyecatcher .text.phone,
.container-contact-eyecatcher .text.mail a {
  font-size: 32px;
  color: #464749;
}
  
.container-customer-quote {}
.container-customer-quote .quote-text {
  padding: 36px 12px;
}
.container-customer-quote .customer-quote {
  font-size: 24px;
  font-style: italic;
  line-height: 120%;
  color: #333333;
  margin-bottom: 12px;
}
.container-customer-quote .quote-left {
  background: url(../images/quote-left.png) center center / auto auto no-repeat;
}
.container-customer-quote .quote-right {
  background: url(../images/quote-right.png) center center / auto auto no-repeat; 
}


.container-calculator-inner {
  overflow-x: hidden;
}

.custom-calculator label {
  color: #ffffff;
  font-size: 18px;
}
.custom-calculator .form-group {
  margin-bottom: 14px;
  min-height: 95px;
}
#stock-calc,
#seed-calc {
  width: 90%;
  position: relative;
}
#stock-calc-crop-area,
#seed-calc-crop-area {
  padding-right: 50px;
}
#stock-calc .slider-selection,
#seed-calc .slider-selection {
  background: rgb(43,101,27);
  background: -moz-linear-gradient(left, rgba(43,101,27,1) 70%, rgba(49,119,30,1) 100%);
  background: -webkit-linear-gradient(left, rgba(43,101,27,1) 70%,rgba(49,119,30,1) 100%);
  background: linear-gradient(to right, rgba(43,101,27,1) 70%,rgba(49,119,30,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2b651b', endColorstr='#31771e',GradientType=1 );
  
  -moz-box-shadow:    inset 0 0 3px rgba(0,0,0,.5);
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.5);
  box-shadow:         inset 0 0 3px rgba(0,0,0,.5);
}
#stock-calc .slider-track,
#seed-calc .slider-track {
  -moz-box-shadow:    inset 0 0 3px rgba(0,0,0,.3);
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.3);
  box-shadow:         inset 0 0 3px rgba(0,0,0,.3);
}
.slider.slider-horizontal {
  width: calc(100% - 12px) !important;
  margin-left: 12px;
}
.slider-tick-label {
  color: #ffffff;
  padding-top: 6px !important;
  font-size: 18px;
}
.slider-tick.round {
  opacity: 0;
  
}
#stock-calc .slider-handle.min-slider-handle.round,
#seed-calc .slider-handle.min-slider-handle.round {
  width: 26px;
  height: 26px;
  margin-top: -4px;
  margin-left: -13px;
  background-image: none;
  background-color: #2B651B !important;
  border: 4px solid rgba(255,255,255,1);
}
 
.calc-result {
  padding-top: 34px;
}
#calculators .calc-result {
  padding-top: 12px;
}
.calc-result .card {
  box-shadow: 1px 1px 20px 0 rgba(0,0,0,0.5) !important;  
}
.calc-result .card-header {
  font-size: 24px !important;
  background: #ffffff;
  text-align: center;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
:not(#calculators) .calc-result .card-header {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.calc-result .card-body {
  padding-right: 34px;
  padding-left: 34px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
:not(#calculators) .calc-result .card-body {
  padding-right: 50px;
  padding-left: 50px;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.calc-result .card-body table {
  margin-bottom: 0 !important;
}
.calc-result .table tr {
  border-top: none;
  margin-bottom: 0 ;
}
.calc-result th {
  font-size: 20px;
  font-weight: normal;
  text-align: left;
  padding-left: 0;
}
.calc-result th, .calc-result td {
  border-bottom: 0;
}
.calc-result tr:first-child th, .calc-result tr:first-child td {
  border-top: 0;
}
.calc-result .value {
  font-size: 20px !important;
  font-weight: bold !important;
  color: #2B651B;
  text-align: right;
}

.stock-calc-element-count input {
  margin-bottom: 1em;
  text-align: center;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #28611a;
  color: #D8D8D8;
  border: 1px solid #D8D8D8;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly]::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #D8D8D8;
}
.form-control:disabled, .form-control[readonly]::-moz-placeholder { /* Firefox 19+ */
  color: #D8D8D8;
}
.form-control:disabled, .form-control[readonly]:-ms-input-placeholder { /* IE 10+ */
  color: #D8D8D8;
}
.form-control:disabled, .form-control[readonly]:-moz-placeholder { /* Firefox 18- */
  color: #D8D8D8;
}



.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.4);
  z-index: 99999;
  display: none;
}
.overlay > .popup > .row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
#calculators {
  position: absolute;
  margin-top: 0;

  z-index: 99999;  
}
#calculators .col-12.pt-5.pb-2 {
  padding-top: 0 !important;
}
#calculators .container.container-calculator-inner.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 0rem !important;
}
#calculators .col-sm-10.offset-sm-1.pb-5 {
  padding-bottom: .5rem !important;
}
#calculators h2 {
  text-transform: none;
  font-size: 32px !important;
  font-weight: normal !important;
  text-align: left;
  margin-left: 8.33333%;
}
#calculators .primary-gradient {
  background: none;
}

#calculators .row .col-6:first-child {
  border-right: 1px solid #4B823C;
}
#calculators .row .col-6 {
  overflow: hidden;
}
#calculators .container-calculator-inner .row.mb-5 {
  display: block;
  flex-wrap: nowrap;
  margin-right: -15px;
  margin-left: -15px;
}
#calculators .col-sm-5 {
  min-width: auto;
  max-width: auto;
  width: auto;    
  flex: 0 1 auto;
  border: 1px solid red;
}
#calculators .card .arrow {
  position: absolute;
  top: -40px;
  left: calc(50% - 20px);
  width: 0;
  border-bottom: 20px solid #ffffff;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
}
#calculators div.text-white {
  text-align: left;
}
#calculators .close {
  position: absolute;
  right: 0;
  margin: 15px 15px 15px 15px;
  width: 16px;
  height: 16px;
  background: url(../images/icons/close-white.svg) center center / auto auto no-repeat;
  z-index: 999999;
}
#calculators #stock-calc,
#calculators #seed-calc {
  width: 100%;
  position: relative;
}

.card .arrow {
  position: absolute;
  top: calc(50% - 20px);
  left: -20px;
  width: 0;
  border-top: 20px solid transparent;
  border-right: 20px solid #ffffff;
  border-bottom: 20px solid transparent;
}
.form-unit {
  position: absolute;
  font-size: 18px;
  right: 12px;
  margin-top: -1.8em;
}
.container-order .card .card-header {
  background-color: transparent;
  border-bottom: 0;
  padding: 24px 0 0 0;
}
.container-jobs .card {
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2) !important;
}
.container-jobs .card-title {
  color: #000000 !important;
  font-size: 18px;
  text-transform: none;
}

.container-jobs .jobs-date {
  display: block;
  color: #9B9B9B !important;
  font-size: 12px;
  margin-top: 0 !important;
  margin-bottom: 12px !important;
}
.container-jobs .card-body {
  min-height: 230px;
  padding-top: 14px !important;
}
.container-jobs .card-text {
  font-size: 14px !important;
}

.tab-contact { color: #333333; }

.tab-contact img {
  display: block; 
  border-radius: 4px;
  width: 100%;
  height: auto;
}

.tab-contact .name { font-weight: bold; }
.tab-contact .position { font-style: italic; margin-bottom: 1em; }
.tab-contact .address { margin-bottom: 1em; min-height: 88px; }
.tab-contact .phone {
  margin-bottom: 1em;
  padding-left: 3em;
  background: url(../images/icons/mobile-black.svg) 6px center / 13px auto no-repeat;
}
.tab-contact .mail {
  margin-bottom: 1em;
  padding-left: 3em;
  background: url(../images/icons/mail-black.svg) 2px center / 22px auto no-repeat;
}
.tab-contact .mail a { color: #333333; }


.navbar-betaseed .container {
  position: relative;
}
.login-link {
  position: absolute;
  z-index: 2;
  right: 29px;
  top: 12px;
}
.login-link a {
  font-size: 14px;
  color: #424242;
  padding-left: 1.75em;
  background: url(../images/icons/login-black.svg) left center / auto auto no-repeat;
}
.login-link a:hover {
  text-decoration: none;
}

.jumbotron-login {
  background: transparent;
}
.jumbotron-login .backlink {
    position: absolute;
    margin-top: 0rem;
    z-index: 2;
}
.jumbotron-password {
  background: transparent;
}
.jumbotron-password .backlink {
    position: absolute;
    margin-top: 0rem;
    z-index: 2;
}
.jumbotron-create-account {
  background: transparent;
}
.jumbotron-create-account .backlink {
    position: absolute;
    margin-top: 0rem;
    z-index: 2;
}

.jumbotron-legal-page {
  background: transparent;
  padding-bottom: 0;
  color: #464749;
}
.jumbotron-legal-page h1 {
  text-transform: none;
  color: #424242;
}
.container-legal-page h2 {
  text-transform: none !important;
  font-size: 24px;
  color: #000000;
  margin-bottom: 1.5em;
}
.container-legal-page h2.legal {
  padding-left: 2.25em;  
  background: url(../images/icons/paragraph-green.svg) 3px center / auto auto no-repeat; 
}
.container-legal-page .text > ul {
  list-style: none;
  margin-bottom: 4em;
  padding-left: 2.5em;
}
.container-legal-page ul li {
  padding-left: 1em;
  margin-bottom: 1em;
}
.container-legal-page ul li::before {
  display: none;
}
.container-legal-page ul li .number {
  position: absolute;
  margin-left: -3.5em;
  font-weight: bold;
}

.container-us-product .card.col-sm-6.py-5 {
  padding-top: 12px !important;
  padding-bottom: 48px !important;
  box-shadow: 2px 4px 20px 0 rgba(190,190,190,0.8) !important;
}

.container-us-product h2.card-title {
  color: #421D00 !important;
  font-size: 32px;
  font-weight: normal;
}
.links.text-right .btn.download .icon,
.container-us-product .btn.download .icon {
  display: inline-block !important;
  margin: -2px 12px -3px 0;
  width: 20px !important;
  height: 20px !important;
  background: url(../images/icons/downloads-white.svg) center center / 18px auto no-repeat;
}
.container.container-products.mb-5 .col-sm-3 div.card .card-functions {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.card-functions a.download .icon {
  display: inline-block !important;
  margin: -2px 12px -3px 0;
  width: 20px !important;
  height: 20px !important;
  background: url(../images/icons/downloads-blue.svg) center center / 18px auto no-repeat;
}
.container-products .col-sm-3 .card-img-top {
  margin-bottom: 1em;
  display: block;
}
.container-products .col-sm-3 .card {
  margin-bottom: 1em;
  display: block;
}
.container-products .col-sm-3 .card-text {
}
.container-products .col-sm-3 .card-functions a {
  width: 100%;
  display: block;
  text-align: center;
  color: #424242;
  padding: 12px;
  border-top: 1px solid #D8D8D8;
}
.container-products .col-sm-3 .card-functions a:hover {
  text-decoration: none;
}
.container-products .col-sm-3 .card-functions a.more {
  background: #047BBB;
  border-top: 0;
  color: #ffffff;
  border-radius: 0 0 0.25rem 0.25rem;
}
.col-12 > .tab-pane {
  border: 1px solid rgba(190,190,190,0.3);
    border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
}
.container-betapoint-premium span.labels {
  display: block;
  margin-top: -60px;
  text-align: right;
  height: 75px;
  width: 100%;  
}
.container-betapoint-premium .labels img {
  display: inline-block;
  margin-left: .25em; 
  border-radius: 50%;
  width: 75px;
  height: 75px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, .1);
}
.container-betapoint-premium .card-title {
  margin-top: .5em;
  font-size: 18px;
  color: #000000 !important;
  min-height: 2.5em;
}

.jumbotron-betapoint-premium {
  background: transparent;
  padding-bottom: 0;
}
.jumbotron-betapoint-premium .backlink {
    position: absolute;
    margin-top: 0rem;
    z-index: 2;
}
.jumbotron-betapoint-premium .col-4 {

}
.container-news .card-body {
  padding: .625em 1.25em .75em 1.25em;
}
.container-news .card-body .news-date {
  display: block;
  color: #9B9B9B;
  font-size: 12px;
  margin-top: 0 !important;
  margin-bottom: .525em !important;
}
.container-news .card-body .card-title {
  font-size: 18px;
  color: #000000 !important;
  margin-bottom: .25em;
}

.container-news-archive h2 {
  text-align: center;
  margin-bottom: 1em;
}
.container-news-archive .card { 
  margin-bottom: 1.5em;
}
.doc-type.pdf {
  background: url(../images/doc-type-pdf.jpg) center center / auto auto no-repeat;
  height: 45px;
}
.col-sm-5.headline {
  font-weight: bold;
  color: #424242 !important;
}
.col-sm-5.headline,
.col-sm-6.date {
  padding-top: 13px;
  padding-bottom: 13px;
}
.col-sm-6.date {
  margin-left: -24px;
  background: url(../images/icons/downloads-blue.svg) right center / 22px auto no-repeat;
}
.sticky-container {
  position: fixed;
  top: 30%;
  right: 0;
  margin-left: -52px;
  width: 52px;
  height: 52px;
  z-index: 999;  
}
.sticky-button {
  display: block;
  box-shadow: 0 0 10px rgba(0,0,0,0.4);
  width: 54px;
  height: auto;
  background-color: #047BBB;
  background-position: center 12px;
  background-size: 30px auto;
  background-repeat: no-repeat;
  margin-bottom: 12px;
  border-radius: .25rem 0 0 .25rem;
  padding-top: 1px;
  padding-bottom: 1px;
}
.sticky-button:hover {
  text-decoration: none;
}
.sticky-button .sticky-button-label {
 display: block;
 -moz-transform: rotate(-90deg);
 -ms-transform: rotate(-90deg);
 -o-transform: rotate(-90deg);
 -webkit-transform: rotate(-90deg);
 transform: rotate(-90deg);
 font-weight: normal;
 color: #ffffff;
 width: 100px;
 height: 18px;
 margin-left: -26px;
 margin-top: 40px;
 margin-bottom: 54px; 
 font-size: 14px;
}
/*
.sticky-button.contact {
  height: 150px;
  background-image: url(../images/icons/contact-white.svg);
}
.sticky-button.seed-calculator {
  height: auto;
  padding-top: 64px;  
  background-image: url(../images/icons/calculator-white.svg);
}
*/
.sticky-button .sticky-button-icon {
  display: block;
  width: 32px;
  margin: 9px auto;
}


.stock-value {
  font-size: 50px;
  color: #2B651B;
  font-weight: bold;
  text-align: center;
}


.container-contact-de {
  margin-bottom: 0;
  background: #31761e;
  overflow-y: hidden;

  overflow-x: hidden !important;  
  min-height: calc(100vw * .7);
}
.container-contact-de .slide-wrapper {
  float: left;
  position: relative;
  width: 500px;
  overflow-x: hidden;
}

.container-contact-us .map-wrapper,
.container-contact-de .map-wrapper {
  position: absolute;
  right: 0;
  overflow-y: hidden !important;
  width: calc(100% - 500px);
  margin-left: 500px;
}



#contact-de-accordion {
  width: 100%;
  border-radius: 0 !important;
  border-top: 1px solid #4B823C;
  margin-right: -3px
}
#contact-de-accordion .card {
  border-radius: 0 !important;
  border: none;
  box-shadow: none !important; 
}
#contact-de-accordion .card .card-header {
  border-radius: 0 !important;
  padding: 0 !important;    
}
#contact-de-accordion .card.region > .card-header {
  border-bottom: 1px solid #4B823C;  
}

#contact-de-accordion .card-header a {
  display: block;
  cursor: pointer;
  padding: 1.5em 1.5em 1.5em 2.75em;
}
#contact-de-accordion .card.region > .card-header a {
  background: #31761e;
  color: #fff !important;
  background-image: url(../images/icons/arrow-right-white.svg) !important;
  background-position: right 28px center !important;
  background-repeat: no-repeat !important;
  background-size: 10px auto !important;    
}
#contact-de-accordion .card.region > .card-header a[aria-expanded=true] {
  background: #296418;
}  

#contact-de-accordion .card-body {
  padding: 0rem !important;
}
#contact-de-accordion .card-body .row {
  border-bottom: 1px solid #D8D8D8;
}
.map-wrapper .contact-map {
  width: 100% ;
  height: auto;
}
.slide-wrapper {
  position: absolute;
  top: 0;
  background: #31761e;
  width: 27vw;
  z-index: 2;
  padding-bottom: 0;
}

.location-marker {}
.location-marker a {
   position: absolute;
   width: 1px;
   height: 1px; 
}
.location-marker a.region1 img,
.location-marker a.region2 img,
.location-marker a.region3 img {
  width: 20px;
  height: auto;
  margin: -58px 0 0 -10px;
  
}
a .indicator {
  background: #FFDC00;
  border: 2px solid #ffffff;
  width: 18px;
  height: 18px;  
  display: inline-block;
  border-radius: 50%;  
  margin: 0 12px -3px 0;
}
a[data-target="#region2"] .indicator {
  background: #2B651B;
}

a[data-target="#region3"] .indicator {
  background: #421D00;
}
.container-contact-us {
  margin-bottom: 3em;
  overflow: hidden;
}
.container-contact-us .slide-wrapper {
  float: left;
  position: relative;
  width: 500px;
}
.container-contact-us .slide-wrapper .card {
  background: transparent;
}
.container-contact-us .slide-wrapper .card a {
  cursor: pointer;
}
.container-contact-us .map-wrapper {
  position: absolute;
  width: calc(140%);
  margin-left: 0; 
  margin-right: -40%; 
}




#contact-us-accordion {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;  
  border-radius: 0 !important;
  border-top: 1px solid #4B823C;
}
#contact-us-accordion .card {
  border-radius: 0 !important;
  border: none;
  box-shadow: none !important; 
}
#contact-us-accordion .card .card-header {
  border-radius: 0 !important;
  padding: 0 !important;    
}
#contact-us-accordion .card.region > .card-header {
  border-bottom: 1px solid #4B823C;  
}

#contact-us-accordion .card-header a {
  display: block;
  cursor: pointer;
  padding: 15px 1.5em 15px 28px;
  background-image: url(../images/icons/arrow-right-white.svg) !important;
  background-position: right 28px center !important;
  background-repeat: no-repeat !important;
  background-size: 10px auto !important;  
}
#contact-us-accordion .card.region > .card-header a {
  background: #31761e;
  color: #fff !important;
}
#contact-us-accordion .card.region > .card-header a[aria-expanded=true] {
  background: #296418;
}  
#contact-us-accordion .card.region > .card-header a.active {
  background-color: #2b651b;
}



/** Country Selection (#country-selection) [Rev2]**/
#country-selection {}
#country-selection .modal-dialog {
  max-width: 560px;
}
#country-selection .modal-header {
  border-bottom: 0;
}
#country-selection .modal-header .close {
  position: relative;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 1em 1em 0 auto;
  background: url(../images/icons/close-blue.svg) center center / 20px auto no-repeat;
}
#country-selection .modal-header .close span {
  opacity: 0 !important;
}
#country-selection .modal-body {
  padding-top: 0;
  padding-bottom: 4em;
}
#country-selection h2 {
  color: #424242;
  font-size: 32px;
  text-transform: none;
  margin-bottom: 1.75em;
}
#country-selection h3 {
  font-weight: bold;
  font-size: 24px;
  color: #000000;
  text-transform: none;
  margin-bottom: 1em;
}
#country-selection ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#country-selection li {
  margin-bottom: 1em;
}
#country-selection a {
  color: #333333;
  font-size: 16px;
  padding-left: 24px;
  background: url(../images/icons/arrow-blue.svg) left center / 9px auto no-repeat;
}
#country-selection a:hover {
  color: #047BBB;
  text-decoration: none;
}
/** END: Lang Selection (#country-selection) [Rev2]**/

.container-facebook {
  margin-bottom: 5em; 
}
.facebook-wrapper {
  width: 500px;
  margin: 0 auto;
  margin-bottom: 5em;
}
.card-deck.owl-carousel.owl-theme {
  position: relative;
}
.owl-carousel .owl-stage-outer {
  padding: 24px 5px 36px 5px;
  margin-right: -7px;
  margin-left: -7px;  
}
.owl-carousel .owl-nav {
  margin-top: 0;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #047BBB;
}
.owl-theme .owl-dots {
  display: inline-block;
  background-color: transparent !important;
  padding: 0 !important;
  margin: 0 12px -12px 12px !important;
}
.owl-theme .owl-dots .owl-dot:focus,
.owl-theme .owl-dots .owl-dot {
  margin: 0 0 0 0;
  padding: 0 !important;  
  background-color: transparent !important; 
  outline: none !important;    
}
.owl-theme .owl-dots .owl-dot span {
  background: #D8D8D8;
  width: 8px;
  height: 8px;
  margin: -13px 5px 0 5px;
}
.container-products h5.card-title {
  font-weight: bold;
  letter-spacing: 0;
}
.card-deck.owl-carousel.owl-theme {
  padding-left: 30px;
}
.owl-nav .owl-next:focus,  
.owl-nav .owl-next:hover,
.owl-nav .owl-next {
  background: transparent url(../images/icons/arrow-blue-right.svg) center center / 9px auto no-repeat !important;
  outline: none !important; 
}
.owl-nav .owl-prev:focus, 
.owl-nav .owl-prev:hover,
.owl-nav .owl-prev {
  background: transparent url(../images/icons/arrow-blue-left.svg) center center / 9px auto no-repeat !important;
  outline: none !important; 
}
.owl-nav .owl-next span, 
.owl-nav .owl-prev span {
  font-size: 30px;
  padding: 0;
  margin: 0;
  line-height: 100%;
  color: #047BBB;
  opacity: 0;
}
.container.container-products .row.text-center .col-12.py-5 {
  padding-top: 2em !important;
}
.owl-nav.main-nav {
  border: 0;
  margin: 0 -30px 0 0;
  padding: 10px;
  position: absolute;
  top: calc(50% - 60px);
  left: -35px;
  right: -35px;
  z-index: -1;
}
.owl-nav.main-nav .owl-next {
  float: right;
}
.owl-nav.main-nav .owl-prev {
  float: left;
}
.owl-nav.main-nav .owl-next,
.owl-nav.main-nav .owl-prev {
  background-size: 24px auto !important; 
}
.owl-nav.main-nav .owl-next span, 
.owl-nav.main-nav .owl-prev span {
  font-size: 55px;
  padding: 18px;
  margin: 0;
  line-height: 100%;
  color: #047BBB;
  opacity: 0;
}

textarea, select, input, button, .nice-select.form-control, .form-group {
  outline: none;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.nice-select.form-control.open,
.nice-select.form-control.open .list {
  box-shadow: 0 4px 10px 0 rgba(227,227,227,0.5);
}

.nice-select,
select.form-control {
  background-image: url(../images/icons/arrow-down-blue.svg);
  background-position: right 14px center;
  background-size: 16px auto;
  background-repeat: no-repeat;
}
.nice-select.open {
  background-image: url(../images/icons/arrow-up-blue.svg);
  -webkit-border-top-right-radius: 5px !important;
  -webkit-border-top-left-radius: 5px !important;
  -moz-border-radius-topright: 5px !important;
  -moz-border-radius-topleft: 5px !important;
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
  -webkit-border-bottom-right-radius: 0 !important;
  -webkit-border-bottom-left-radius: 0 !important;
  -moz-border-radius-bottomright:0 !important;
  -moz-border-radius-bottomleft: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;  
}
.nice-select:after {
  display: none;
}
.nice-select .list {
  width: 100%;
  background-color: #fff;
  -webkit-border-top-right-radius: 0 !important;
  -webkit-border-top-left-radius: 0 !important;
  -moz-border-radius-topright: 0 !important;
  -moz-border-radius-topleft: 0 !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  -webkit-border-bottom-right-radius: 5px !important;
  -webkit-border-bottom-left-radius: 5px !important;
  -moz-border-radius-bottomright: 5px !important;
  -moz-border-radius-bottomleft: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-21px);
  -ms-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 99;
}

.container-contact.container-contact-us .text-z-index.p-5.text-white {
  padding: 80px 25px 25px 25px !important;
}
.container-contact.container-contact-us .text-z-index.p-5.text-white p {
  margin-bottom: 0 !important;
}
.categories-selector {
  position: absolute;
  width: 1320px;
  margin-left: -175px;
  bottom: 14rem;
}
.container.container-news .card {
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2) !important;
}
.container.container-news .card h5 {
  font-weight: normal !important;
}
.container.container-news .card .card-text {
  font-size: 14px;
  line-height: 22px;
  min-height: 90px;
}

.container-teaser.container-regions {
  padding-top: 1rem !important;
  padding-bottom: 0 !important;
}
.container-teaser.container-regions .row.text-center.mb-5{
  margin-bottom: 2em !important;
}
.container.container-products.mb-5 .col-sm-3 div.card {
  box-shadow: 2px 4px 20px 0 rgba(190,190,190,0.3) !important;
  min-height: 550px;
}
.container.container-products.mb-5 .col-sm-3 div.card .card-body {
  padding: 0 20px 0 20px !important;
  min-height: 230px;  
}



.col-12.col-md-8.col-lg-6 > h2.pb-3 {
  padding-bottom: 0 !important;
}

.row.text-center.mb-5.justify-content-center {
  margin-bottom: 2rem !important;
}

.container-competence-cards h2.pb-3 {
  padding-bottom: 0 !important;
}

.container.container-competence-cards.primary-gradient.py-5 {
  padding-top: 0 !important;
}

.container.container-betapoint.py-5 h2.pb-3 {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.container.container-betapoint.py-5 .col-12.py-5 {
  padding-bottom: 3em !important;
}

.container.container-betapoint.py-5 .col-md-12.mb-5 {
  margin-bottom: 1.75rem !important;
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /* no standardized syntax available, no ie-friendly solution available */
}
.container-betapoint-steps-full {
  background-repeat: no-repeat;
}
.container.container-product img {
  min-width: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
  height: auto !important;
}
.text.mail a {
  color: #464749 !important;
  text-decoration: none;
}



/* Table Styles */ 
table {
  width: 100%;
  color: #333333;
  margin-bottom: 1em;
}
table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #cccccc !important;
}
:not(.calc-result) th, :not(.calc-result) td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #cccccc !important;
}
.calc-result tr:first-child td,
.calc-result tr:first-child th {
  border-top: none !important;
}
table strong {
  color: #2B651B;
  font-weight: bold;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fafafa;
}
table a,
p a {
  color: #047BBB !important;
  text-decoration: underline;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
/* END Table Styles */ 


/* Modal */
.modal-content .py-2.px-3 {
  padding: 0 2em !important;
}
.modal-content .container-article .col-sm-8 {
  flex: 0 0 100%;
  max-width: 100%;
}
.modal-body {
  padding: 1em 2em;
}
/* END Modal */


/* Mobile Table */
.table-container {
  width: 100%;
  overflow-y: auto;
  margin: 0 0 1em;
}

.table-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
}

.table-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid #fff;
  background-color: rgba(0, 0, 0, .3);
}
/* END Mobile Table */

/* iFrame CSS */
#iframespeet {
  width: 100%;
}
/* END iFrame CSS */


input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.container-news-cards .col-sm-12 .card-body .img-fluid {
  max-width: 100%;
  min-width: 100%;
  height: auto;
}





@media (max-width: 1600px) {
  .jumbotron-fluid.jumbotron-header .jumbotron-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 80px auto 0 auto;
    width: 920px;
    height: auto;
  }
}

@media (max-width: 1460px) {
  .owl-nav.main-nav {
    display: none;
  }
}
@media (max-width: 1320px) {
  .cust-gallery .cust-media {
    width: 47.5% !important;
    margin-right: 5% !important;
  }
  .cust-gallery .img-fluid:nth-child(even) {
    margin-right: 0 !important;
  }
  .cust-gallery .cust-media img {
    min-width: 100% !important;
    max-width: 100% !important;
    height: auto !important;
  }  
}
@media (max-width: 1360px) {
  .jumbotron-fluid.jumbotron-header .jumbotron-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 50px auto 0 auto;
    width: 100%;
    height: auto;
  }
  .categories-selector {
    position: absolute;
    margin-left: 0 !important;
    margin-right: 0 !important;
    bottom: 11rem;
    width: 100%;
  }
  .container-pure-stand.container-products {
    margin-top: -12rem;
  }  
  .categories-selector .row.mt-5 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .jumbotron-fluid.jumbotron-header.limited .jumbotron-content  {
    width: 920px;
    margin: 100px auto 0 auto;
  }
  

}
@media (max-width: 1360px) {
  .container-news-cards .col-sm-12 .card-body .col-md-7 .card-text {
    height: 75px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}



/**
 *   Desktop/Tablet: 1279px 
 */
@media (min-width: 992px) and (max-width: 1279px) {
  .form-group.stock-calc-element-count .col-sm-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}/* (min-width: 992px) and (max-width: 1279px) */


  
/**
 *   Desktop/Tablet: 1199px 
 */
@media (max-width: 1199px) {
  .container.container-news-cards {
    
  }
  .container.container-teaser.container-story .col-md-5 {
    overflow-x: hidden;
  }
  .container.container-teaser.container-story .col-md-5 img.img-fluid {
    height: 100% !important;
    max-width: 133.658% !important;
  }  
  .container.container-teaser.container-story .col-md-7 {
    padding: 60px;
  }  
  .container.container-teaser.container-story .col-md-7 > div {
    padding: 0 !important;
  }
  .container.container-products .col-sm-3 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }  
  .container-contact-de .map-wrapper {
    position: absolute;
    right: 0;
    overflow-y: hidden !important;
    width: calc(100% - 400px);
    margin-left: 500px;
    margin-right: -100px;
  }
  .jumbotron-fluid.jumbotron-header .jumbotron-content {
    width: 960px;
    margin-top: 25px;
  }
  footer > .container:first-child > .row > div.col-md-3 {
    flex: 0 0 20.5% !important;
    max-width: 20.5% !important;
  }
  #nav-tabContent .col-md-5 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  #nav-tabContent .col-md-7 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  #nav-tabContent .col-md-7 .p-5 {
    padding: 1em 1em 1em 0 !important;
  }
  .container.container-news-cards.py-5 .col-sm-6 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 1em;
  }
  .container-pure-stand.container-products {
    margin-top: -9rem;
  }
  .categories-selector {
    position: absolute;
    margin-left: 0 !important;
    margin-right: 0 !important;
    bottom: 8rem;
    width: 100%;
  }
  .container.container-wiki.container-pure-stand .col-md-3 {
    flex: 0 0 33%;
    max-width: 33%;
  }
  .container.container-wiki.container-pure-stand .col-md-9 {
    flex: 0 0 66%;
    max-width: 66%;
  }
  .container.container-wiki.container-pure-stand .col-md-9 .col-sm-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .container.container-wiki.container-pure-stand {}
  .container-full.container-betapoint-steps-full .container,
  .container.container-betapoint-steps-half {
    max-width: 100%;
  }
  .container-betapoint-steps-half .text {
    margin: 2em;
    margin-bottom: 0;
  }
  .container-full.container-betapoint-steps-full .text-white.py-5 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    flex: 0 0 60%;
    max-width: 60%;
  }
}/* @media (max-width: 1199px)  */


/**
 *   Tablet: 1180px 
 */
@media (max-width:1180px) {
  .container-contact-us,
  .container-contact.container-contact-de {
    min-height: auto;
  }
  .container-contact-us .map-wrapper,
  .container-contact.container-contact-de .map-wrapper {
    display: none;
  }
  .container-contact-us .slide-wrapper,
  .container-contact.container-contact-de .slide-wrapper {
    width: 100%;
    padding-bottom: 0;
  }
  #contact-us-accordion .card-header a,
  #contact-de-accordion .card.region > .card-header a {
    background-image: url(../images/icons/arrow-down-white.svg) !important;
    background-size: 14px auto !important;
  }
  #contact-de-accordion .card.region > .card-header a.active {
    background-image: url(../images/icons/arrow-up-white.svg) !important;
  }
  a .indicator {
    display: none;
  }
  #contact-de-accordion .col-3 {
    width: 120px;
    flex: 0 0 25%;
    max-width: 120px;
  }
}/* @media (max-width: 1180px)  */



/**
 *   Tablet: 1100px 
 */
@media (max-width:1100px) {
  .container.container-betapoint-steps-half .row.row-eq-height.row-text-media {
    display: flex;
    flex-direction: row;
  }
  .container.container-betapoint-steps-half .row.row-eq-height.row-text-media .col-sm-6:nth-child(1) { order: 2; }
  .container.container-betapoint-steps-half .row.row-eq-height.row-text-media .col-sm-6:nth-child(2) { order: 1; }
  
  .container.container-betapoint-steps-half .row.row-eq-height.row-media-text .col-sm-6,
  .container.container-betapoint-steps-half .row.row-eq-height.row-text-media .col-sm-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .container-betapoint-steps-half .text {
    margin: 4em;
  }
}/* @media (max-width: 1100px)  */





/**
 *   Tablet: 991px 
 */
@media (max-width: 991px) {
  body {
    /*overflow-x: hidden;*/
  }
  .navbar.navbar-expand-lg.navbar-betaseed {}
  .navbar.navbar-expand-lg.navbar-betaseed .container {
    max-width: 100%;
  }
  .navbar-betaseed {
    padding: 0 1rem;
    height: auto;
  }
  .navbar-betaseed .navbar-brand {
    background: #ffffff;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    display: block;
    height: 136px;
    width: 140px;
    margin-top: -40px;
    margin-bottom: -40px;    
    position: relative;
    z-index: 999;
    transition: all .25s ease-in-out;
  }
  .login-link {
    top: -30px;
    right: .25rem;
  }
  .navbar-toggler[aria-expanded=false] {
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1;
    border-radius: 0.175rem;
    background: transparent url(../images/icons/burger-menu-white.svg) 6px center / 50px 24px no-repeat;
  }
  .navbar-toggler[aria-expanded=true] {
    background: transparent url(../images/icons/close-white.svg) 6px center / 50px 24px no-repeat;
  }  
  .navbar-toggler-icon {
    
  }
  .navbar-collapse {
    margin: 0 -1rem;
    padding-top: 55px;
    z-index: 998;
    background: linear-gradient(172.92deg, #31761E 0%, #2B651B 100%);
  }  
  .navbar-collapse .nav-link {
    text-align: center;
  }
  
  .jumbotron.jumbotron-fluid.jumbotron-header .jumbotron-content {
    max-width:540px;
  }  
  .jumbotron.jumbotron-fluid.jumbotron-header h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .container-pure-stand {
    margin-top: -6rem;
  }
  .container-pure-stand.mb-5 {
    margin-bottom: 0 !important;
  }
  .owl-nav.main-nav { display: block; }
  h2 {
    font-size: 26px;
    line-height: 34px;
  }
  
  .container-news-cards .col-sm-6 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 1.5em;
  }
  .container-competence-cards .col-md-4.mb-5 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 2rem !important;
  }
  
  a .indicator {
    display: none;
  }
  footer > .container {
      max-width: 100% !important;
      padding-right: 0;
      padding-left: 0;
  }
  .container-contact .text-z-index.p-5.text-white.mt-5 {
    margin-top: 0 !important;
    padding-left: 15px !important;  
    padding-right: 15px !important;
    padding-bottom: 2rem !important;
  }
  #contact-de-accordion .card-header a {
    display: block;
    cursor: pointer;
    padding:  15px 15px  15px 15px;
  }
  #contact-de-accordion .col-3 {
    width: 120px;
    flex: 0 0 25%;
    max-width: 120px;
  }
  .tab-pane .row .col-md-5,
  .tab-pane .row .col-md-7,
  .tab-pane .col-sm-6.tab-contact {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  } 
  .tab-pane .row .col-md-5 img {
    min-width: 100%;
  }
  .tab-pane .col-sm-6.tab-contact {
    padding-bottom: 1.5rem !important;
  }  
  .container-products > .row > .col-sm-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .jumbotron-fluid.jumbotron-header .jumbotron-content {
    margin: 0 auto 0 auto !important;
    width: 720px;
  }
  .jumbotron-fluid.jumbotron-header .jumbotron-content h1 {
    margin-top: 4.5rem !important;
  }  
  .container-product .row {
    display: flex;
    flex-direction: row;
  }
  .container-product .row > .col-sm-4:nth-child(1) { order: 2; }
  .container-product .row > .col-sm-4:nth-child(2) { order: 1; }
  .container-product .row > .col-sm-4:nth-child(3) { order: 3; }
  .container-product .row > .col-sm-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .container-product .row > .col-sm-4 .card-body.block-2 {
    text-align: center;
  }
  .container-product .row > .col-sm-4 .card-body.block-3 {
    padding-top: 0;
  }
  .container-product .links {
    margin: 0 -1rem;
  }
  .container-product .links a.btn {
    display: block;
    border-radius: 0;
    margin-left: 0;
    border-top: 1px solid #ffffff;
  }
  .container-product .links > a.btn:last-child {
    border-left-bottom-radius: 0.25rem !important; 
    border-right-bottom-radius: 0.25rem !important;
    -webkit-border-bottom-right-radius: 0.25rem !important; 
    -webkit-border-bottom-left-radius: 0.25rem !important; 
    -moz-border-radius-bottomright: 0.25rem !important; 
    -moz-border-radius-bottomleft: 0.25rem !important; 
  }  
  .container-fluid.container-characters .row > .col-sm-6 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 2rem;
  }
  .container-fluid.container-characters .media-element img {
    min-width: 100%;
  }
  .container-customer-quote .row > * {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    text-align: center;
  }
  .container-customer-quote .row .col-sm-2.quote-left,
  .container-customer-quote .row .col-sm-2.quote-right {
    min-height: 120px;
  } 
  .container-customer-quote .row .col-sm-2.quote-left {
    margin-bottom: 2.5rem;
  }  
   .container-customer-quote .row .col-sm-2.quote-right {
    margin-top: 1rem;
  }      
  .container-order .card-group .card {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .container-order .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: 0;
  }
  .container-order .card-group > .card:nth-child(2) {
    border-top-right-radius: 0.25rem !important;
    border-bottom: 0;
  }
  .container-order .card-group > .card:nth-child(3) {
    border-left: 1px solid rgba(0, 0, 0, 0.125) !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .container-order .card-group > .card:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0;
  }
  .container-calculator .col-sm-8.offset-sm-2.pb-5 {   
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0;
  }
  .container-calculator .col-sm-5.offset-sm-1,
  .container-calculator .col-sm-5.calc-result {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0;
  }
  .stock-calc, #seed-calc {
    width: 100%;
    position: relative;
  }
  .container-calculator .card .arrow {
    position: absolute;
    top: -40px;
    left: calc(50% - 20px);
    width: 0;
    border-bottom: 20px solid #ffffff;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
  }
  .container-news .col-sm-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .container-wiki .col-md-3 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .container-wiki .col-md-9 {
    flex: 0 0 70%;
    max-width: 70%;
  }  
  .container-wiki .col-md-9 .col-sm-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .container-bonus .col-sm-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .container-bonus .col-sm-3 .card {
    margin-bottom: 2rem;
  }
  .container-betapoint-steps-half {
    max-width: 100%;
  }
  .jumbotron.jumbotron-fluid.jumbotron-header .col {
    flex-basis: 0 !important;
    flex-grow: 1 !important;
    max-width: 30% !important;
  }
  .navbar-betaseed + .container-contact .text-z-index.p-5.text-white.mt-5 {
    margin-top: 3rem !important;
  }
  .jumbotron-password .col-sm-6.offset-sm-3 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-left: 0!important;
  }
  .jumbotron-fluid .btn.btn-primary.btn-auto.backlink {
    position: relative;
    margin-top: 50px;
  }
  .container-fluid.container-password .col-sm-6.offset-sm-3,
  .container-fluid.container-login .col-sm-6.offset-sm-3,
  .container-fluid.container-create-account .col-sm-6.offset-sm-3 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-left: 0!important;
  }
  #calculators .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .tab-contact .address {
    margin-bottom: 1em;
    min-height: auto;
  }
  footer .col-md-3 {
    flex: 0 0 25%;
    max-width: 230px;
  }
  footer .col-md-9 {
    flex: 0 0 25%;
    max-width: 200%;
  }  
  .jumbotron.jumbotron-fluid.jumbotron-header .jumbotron-content {
    max-width: 720px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .jumbotron.jumbotron-fluid.jumbotron-header .jumbotron-content .row.mt-5 > .col-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  
  /* US Styles */
  .container-quicklink-tiles .card-deck .card {
    flex: 0 0 100%;
    margin-bottom: calc(4rem + 60px);
  }
  .container-quicklink-tiles .card-deck .card img.a-label.bubble {
    display: block;
    width: 120px;
    height: 120px;
    background: #ffffff;
    position: absolute;
    top: -60px;
    right: calc(50% - 60px);
    border-radius: 50%;
    padding: 4px;
  }
  .container-quicklink-tiles a.card h5,
  .container-quicklink-tiles a.card .card-text,
  .container-quicklink-tiles a.card h5 {
    text-align: center;
  }
  .container-teaser.container-story {}
  .container-teaser.container-story .col-md-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .container-teaser.container-story .col-md-5 .img-fluid {
    max-width: 100% !important;
    width: 100% !important;
    min-width: 100% !important;
    height: auto !important;
    max-height: auto !important;
  }
  .container-teaser.container-story .col-md-7 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 24px 12px;
  }
  .container-teaser.container-wiki .col-md-12:not(.col-lg-8) {
    padding-left: 0;
    padding-right: 0;
  }
  .container-teaser.container-wiki .col-md-12:not(.col-lg-8) .container.container-news {
    padding-left: 0;
    padding-right: 0;
  }
  .container.container-products .col-sm-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }    
  #calculators .row .col-6:first-child {
    border-right: 0;
    border-bottom: 1px solid #4B823C;
  }  
  #nav-tabContent .col-md-7 .p-5 {
    padding: 1.25rem !important;
  }
  
  .container-betapoint .card-body.py-5 {
    padding-top: 2em !important; 
    padding-bottom: 2em !important;
    min-height: 200px;
  }
  .container-betapoint .card .card-text {
    text-align: center;
    font-size: 24px;
    line-height: 120%;
    color: #424242;
  }
  .container-contact-de {
    min-height: auto;
  }
  .container-pure-stand.container-products {
    margin-top: -10rem;
  }
  footer > .container:first-child > .row > div.col-md-3 {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .categories-selector {
    position: absolute;
    margin-left: -15px !important;
    margin-right: 0 !important;
    bottom: 6rem;
    width: 720px;
  }
  .container-pure-stand.container-products {
    margin-top: -7rem;
  }
  .container.container-wiki.container-pure-stand .col-md-3 {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .container.container-wiki.container-pure-stand .col-md-9 {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .container.container-wiki.container-pure-stand .col-md-9 .col-sm-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .container-full.container-betapoint-steps-full .container {
    max-width: 720px;
  }
  .container-full.container-betapoint-steps-full .text-white.py-5 {
    padding-top: 180px !important;
    padding-bottom: 80px !important;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .container-full.container-betapoint-steps-full {
    background-image: none !important;
    background-size: auto 70%;
    background-position: top -60px center;  
    background-repeat: no-repeat;
  }
  .container-full.container-betapoint-steps-full > .container {
    background-image: url(../images/devices.svg);
    background-size: 120px auto;
    background-position: 15px 80px;  
    background-repeat: no-repeat;
  }
  
  /* Tab / Accordion */
  .nav.nav-tabs.nav-fill { display: none; }
  #nav-tabContent .card-header { display: block; }
  #nav-tabContent .card-header + div { display: none; }
  #nav-tabContent.mobil {
    border-radius: 5px !important;
  } 
  #nav-tabContent.mobil .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #ffffff;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom: 0 solid rgba(0, 0, 0, 0.125);
    color: #6D6D6D;
    border-radius: 0;
    background-image: url(../images/icons/arrow-down-blue.svg) !important;
    background-position: right 19px center !important;
    background-repeat: no-repeat !important;
    background-size: 19px auto !important;
  } 
  #nav-tabContent.mobil > .tab-pane:first-child .card-header {
    -webkit-border-top-left-radius: 0.25rem;
    -webkit-border-top-right-radius: 0.25rem;
    -moz-border-radius-topleft: 0.25rem;
    -moz-border-radius-topright: 0.25rem;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  #nav-tabContent.mobil > .tab-pane:last-child .card-header {
    -webkit-border-bottom-right-radius: 0.25rem;
    -webkit-border-bottom-left-radius: 0.25rem;
    -moz-border-radius-bottomright: 0.25rem;
    -moz-border-radius-bottomleft: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }  
  #nav-tabContent.mobil > .tab-pane:last-child .card-header.show,
  #nav-tabContent.mobil > .tab-pane:last-child .card-header.active {
    -webkit-border-bottom-right-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    -moz-border-radius-bottomright: 0;
    -moz-border-radius-bottomleft: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }    
  #nav-tabContent.mobil .card-header.show,
  #nav-tabContent.mobil .card-header.active,
  #nav-tabContent.mobil .card-header:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #2B651B;
    background-image: url(../images/icons/arrow-down-white.svg) !important;
  }    
  #nav-tabContent.mobil .card-header.show,
  #nav-tabContent.mobil .card-header.active {
    background-image: url(../images/icons/arrow-up-white.svg) !important;
  }
  #nav-tabContent .col-md-7 .p-5,
  #nav-tabContent .col-md-12 .p-5 {
    padding: 1.25rem !important;
  }
  #nav-tabContent .col-md-12.tab-contacts .px-5 {
    padding: 1.25rem !important;
  }
  /* END Tab / Accordion */
  .cust-media,
  .cust-gallery .cust-media {
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: .5em;
  }
  .cust-gallery .img-fluid:nth-child(even) {
    margin-right: 0 !important;
  }
  .cust-media img,  
  .cust-gallery .cust-media img {
    min-width: 100% !important;
    max-width: 100% !important;
    height: auto !important;
  }
  .jumbotron-article.jumbotron-fluid.jumbotron-header .jumbotron-content h1 {
    margin-top: 0 !important;
  }
  .container.container-teaser.container-story .col-md-7 {
    padding: 2.25em;
  }
  .container-regions form .col-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .container-legal-page h2.legal {
    padding-left: 2.25em;  
    background: url(../images/icons/paragraph-green.svg) 0 top / 34px auto no-repeat; 
  }
  .container.container-product.container-us-product {
    position: relative;
  }
  .container.container-product.container-us-product .card.col-sm-6 {
    flex: 0 0 calc(100% - 30px);
    max-width: calc(100% - 30px);
    position: static;
    padding-bottom: 0 !important;
  }
  .container.container-product.container-us-product .card.col-sm-6:first-child {
    margin-bottom: 2em;
  }
}/* @media (max-width: 991px)  */


@media (min-width: 768px) and (max-width: 920px) { 
  .jumbotron-fluid.jumbotron-header .jumbotron-content h1 {
    margin-top: 3.5rem !important;
  }
}



@media (min-width: 991px) { 
  .tab-content .card-header { display: none !important; }
}

/**
 *   Tablet: 767px
 */
@media (max-width: 767px) {
  .container-competence-cards .col-md-4.mb-5 {
    flex: 0 0 100% !important;
    max-width: 100% !important;    
    margin-bottom: 1rem !important;
  }
  .container-betapoint .col-md-4.mb-5 {    
    margin-bottom: 1rem !important;
  }
  .container.container-betapoint.py-5 .col-md-12.mb-5 {    
    margin-bottom: 2rem !important;
  }  
  footer .col-md-3 {
    flex: 0 0 33% !important;
    max-width: 33% !important;  
  }
  footer .col-md-9 {
    flex: 0 0 67% !important;
    max-width: 67% !important;  
  }
  .container-products > .row > .col-sm-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .container-pure-stand,
  .container-pure-stand.container-products {
    margin-top: 0;
  }
  .container-news-cards .col-md-4 img {
    min-width: 100%;
  }
  .container-news-cards .col-md-7.d-flex.align-items-center  {
    padding: 1.25rem 2rem;
  }  
  .container-news .col-sm-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .login-link {
    display: block;   
  } 
  .card-deck.owl-carousel.owl-theme {
    padding-left: 30px;
  }
  .container-product .card div.a-label {
    width: 75px;
    height: 75px;
    background: #ededed;
    position: absolute;
    top: -20px;
    right: -20px;
    border-radius: 50%;
    text-align: center;
    font-size: 20px;
    line-height: 110%;
    padding: 18px 4px;
    color: #616161;
    transform: rotate(30deg);
    letter-spacing: -0.005em;
  }
  
  .container-wiki .col-md-9,
  .container-wiki .col-md-3 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 1rem;
  }
  .container-news .col-sm-4 {
  }

  .col-md-12.tab-contacts .row.px-5.py-4 {
    padding-left: 1rem !important;
  }
  .owl-nav.main-nav {
    display: block;
  }

  .jumbotron.jumbotron-fluid.jumbotron-header {
    background: #ffffff;

  }
  .jumbotron.jumbotron-fluid.jumbotron-header .jumbotron-content {
    position: relative;    
    max-width: 100%;
  }
  .jumbotron.jumbotron-fluid.jumbotron-header .jumbotron-content h1 {
    margin-top: 1em !important;    
    margin-bottom: 0.5em !important;
    color: #424242;
  }
  .categories-selector {
    position: relative;
    width: auto;
    margin: 0 auto;
    padding: 0 0;
    bottom: auto;
  }
  .categories-selector .col-3 {
    margin-bottom: .5em;
  }
  .jumbotron.jumbotron-fluid.jumbotron-header .jumbotron-content form .form-row.mt-5 {
    margin-top: 0 !important;
  }
  .jumbotron.jumbotron-fluid.jumbotron-header .jumbotron-content form .col-4,
  .jumbotron.jumbotron-fluid.jumbotron-header .jumbotron-content form .col,
  .jumbotron.jumbotron-fluid.jumbotron-header .jumbotron-content .categories-selector .col-3 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .jumbotron.jumbotron-fluid.jumbotron-header .jumbotron-content form .col-4,
  .jumbotron.jumbotron-fluid.jumbotron-header .jumbotron-content form .col {
    margin-bottom: .5em;
  }
  .jumbotron.jumbotron-fluid.jumbotron-header .jumbotron-content form .col input[type=submit].btn {
    min-width: 100% !important;
    max-width: 100% !important;
  }
  .container-wiki .col-md-3 {
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-bottom: 2em;
  }
  .container-wiki .col-md-9 {
    padding-left: 15px !important;
  }
  footer > .container:first-child > .row {
    padding: 2em 0;
  }
  footer > .container:first-child > .row > div.col-md-3 {
    padding-top: 3em;
  }  
  footer > .container:first-child > .row > div.col-md-3,
  footer > .container:first-child > .row > div.col-md-9 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    text-align: center;
    margin-bottom: 2em;
  }
  footer > .container:last-child {
    width: 100vw !important;
    min-width: 100vw !important;
    max-width: 100vw !important;
    padding: 0 !important;
    margin: 0 0 0 -30px !important;
  }
  footer .row.meta {
    display: flex;
    flex-direction: row;
    padding: 0 !important;
    margin: 0 0 !important; 
  }
  footer .row.meta .col-3:not(.text-right),
  footer .row.meta .col-6.text-center,
  footer .row.meta  .col-3.text-right {
    border-bottom: 1px solid #797979;
    text-align: center !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
    padding: 1.5em 0;
  }
  footer .row.meta .col-3:not(.text-right) {
    order: 2;
  }
  footer .row.meta .col-6.text-center {
    order: 1;
    color: #424242 !important;
    
    padding-bottom: 5.65em !important;
  }
  footer .row.meta .col-6.text-center a, 
  footer .row.meta .col-6.text-center span {
    float: right;
    width: 100%;
    padding: 6px 0;
  }
  footer .row.meta .col-6.text-center span {
    float: right;
    width: 100%;
    padding: 6px 0;
    position: absolute;
    left: 0;
    bottom: 3em;
  } 
  footer .row.meta  .col-3.text-right {
    order: 3;
    border-bottom: 0;
    padding: 3em;
  }
  .container-quicklink-tiles.container-pure-stand {
    margin-top: 4rem !important;
  }
  .tab-content .p-5 {
    padding: 1em !important;
  } 
  .owl-nav.main-nav {
    display: none;
  }
  .jumbotron.jumbotron-fluid.jumbotron-header .jumbotron-content {
    max-width: 540px;
    padding-right: 0;
  }
  .container-products .card:hover {
    transform: scale(1);
  }
  .card div.a-label {
    width: 70px;
    height: 70px;
    background: #ededed;
    position: absolute;
    top: -23px;
    right: 0;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    line-height: 110%;
    padding: 12px 4px;
    color: #616161;
    transform: rotate(30deg);
    letter-spacing: -0.005em;
    z-index: 9;
  }
  .container-product .card div.a-label {
    width: 75px;
    height: 75px;
    background: #ededed;
    position: absolute;
    top: -30px;
    right: 0;
    border-radius: 50%;
    text-align: center;
    font-size: 20px;
    line-height: 110%;
    padding: 18px 4px;
    color: #616161;
    transform: rotate(30deg);
    letter-spacing: -0.005em;
  }
  .container.container-wiki.container-pure-stand .col-md-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .container.container-wiki.container-pure-stand .col-md-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .jumbotron.jumbotron-fluid.jumbotron-article .jumbotron-content {
    max-width: 100%;
    padding-right: 15px;
  }
  .jumbotron.jumbotron-fluid.jumbotron-article .jumbotron-content > .container {
    max-width: 100%;
    padding: 0;
  } 
  .jumbotron.jumbotron-fluid.jumbotron-article .jumbotron-content > .container .col-sm-8 {
    flex: 0 0 100%;
    max-width: 100% !important;
  }
  .container-article {
    max-width: 100%;
  }  
  .container-article .col-sm-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  
    
  
  // Owl Carousel Counter
  .owl-item {}
  .owl-prev {
  }
  
  .owl-next {
  }
  .owl-theme .owl-dots .owl-dot span {
    width: 0;
    height: 0;
    margin: 0 0 0 0 !important;
  }
  .owl-dots {
      margin: 0 12px !important;
      min-width: 80px;
      &:after {
        content: counter(slides-num);
        display: inline-block;
        font-size: 16px;
        font-weight: normal;
        vertical-align: middle;
        padding-left: 5px;
        color: #D8D8D8;
        margin-top: -7px;
      }
    }
  
    .owl-dot {
      display: inline-block;
      counter-increment: slides-num;
      margin-right: 5px;
  
      span {
        display: none;
      }
  
      &.active {
  
        &:before {
          content:  counter(slides-num) " /";
          display: inline-block;
          vertical-align: middle;
          font-size: 16px;
          position: relative;
          left: 0;
          top: 0;
          margin-top: -7px;
          color: #D8D8D8;
        }
      }
    }
  
  .info {
    text-align: center;
    margin-top: 110px;
  }
  // END Owl Carousel Counter
  
  .container-teaser > .row.text-center.mb-5.justify-content-center {
    margin-bottom: 1em !important;
  }
  .container-teaser {
    overflow-x: hidden;
  }   
  .container-teaser .jumbotron {
    overflow-x: hidden;
    width: 100vw;
  }   
  .container-teaser .jumbotron > img {
    min-width: 150%;
    width: 150%;
    max-width: 150%;
    margin-left: -50%
  } 
  .container-teaser .jumbotron-content.text-center {
    position: absolute!important;
    margin-top: 0 !important;
    top: calc(50% - 60px) !important;
  }
  footer > .container:first-child > .row {
    padding: 0 0;
  }
  footer .col-md-6 {
    padding: 3em 15px !important;
  }
  footer .col-md-6:first-child {
    border-bottom: 1px solid #797979;
  }
  footer .col-md-6 img {
    position: relative;
    top: 0;
    left: 0;
    width: 50px;
    height: auto;
  }
  .container.container-products .col-sm-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .container.container-products.mb-5 .col-sm-3 div.card .card-body {
    min-height: 295px;
  }
  .container.container-product.container-us-product .card.col-sm-6 .col-sm-10.offset-sm-1.card-text {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0 !important;
  }
  .container-product .btn.btn-primary.download {
    min-width: 100%;
    margin-bottom: .5em;
  }
}/* @media (max-width: 767px)  */




/**
 *   Mobile: 575px
 */
@media (max-width: 575px) {
  .navbar-betaseed {
    background: #2b651b;
    height: 50px;
    margin: 0 0 0 0;
    padding: 0 3rem;
  }
  .navbar-betaseed .navbar-brand {
    margin-top: 0;
    margin-bottom: -50px;
    height: 98px;
    width: 109px;
  }
 .navbar.navbar-expand-lg.navbar-betaseed {}
  .navbar.navbar-expand-lg.navbar-betaseed .container {
    max-width: 100%;
  }
  .navbar-betaseed {
    padding: 0 1rem;
    height: auto;
  }
  .navbar-betaseed .navbar-brand img {
    display: block;
    height: 65px !important;
    margin: 0 15px 15px 15px;
    width: 80px !important;
  }
  .login-link {
    position: absolute;
    z-index: 2;
    right: 0;
    left: 0;
    background: #ffffff;
    top: auto;
    width: auto;
    bottom: -49px;
    margin: 0 -1rem;
    text-align: center;
    display: none;   
  }
  .login-link a {
    display: inline-block;
    padding: 14px 0 14px 23px;
    text-align: center;
    background: url(../images/icons/login-black.svg) left center/ 14px auto no-repeat;
  }
  .navbar-collapse .navbar-nav.ml-auto {
    margin-bottom: 4rem !important;
  }
  .navbar-collapse {
    border-top: 1px solid #ffffff;
  }
  footer .col-md-3 {
    flex: 0 0 100% !important;
    max-width: 100% !important;  
    margin-bottom: 3rem;
  }
  footer .col-md-9 {
    flex: 0 0 100% !important;
    max-width: 100% !important;  
  }
  .container-order .card-group > .card {
    flex: 0 0 100% !important;
    max-width: 100% !important;  
  }  
  .container-order .card-group > .card:first-child,
  .container-order .card-group > .card:nth-child(2),
  .container-order .card-group > .card:nth-child(3),
  .container-order .card-group > .card:last-child {
    border-radius: 0.25rem !important;
    border-bottom: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
  .container-product .row {
    margin: 0;
  }

  .card-body.block-2 img {
    min-width: 100%;
    max-width: 100%;
    height: auto;
  }
  .container-contact .col-9 {
  flex: 0 0 75%;
  max-width: 66%;
  }
  .container-teaser.jumbotron.jumbotron-fluid.jumbotron-header .jumbotron-content {
    max-width: 100%;
    min-width: 100%;
    margin: 20px 0 0 15px !important;
    padding-left: 0;
    padding-right: 15px;
  }  
  .jumbotron.jumbotron-fluid.jumbotron-header {
    overflow-x: hidden !important;
  }
  .jumbotron.jumbotron-fluid.jumbotron-header form .col-3 {
    margin-right: 0;
    margin-left: 0;
    padding-right: 30px;
  }  
  .sticky-container {
    position: fixed;
    top: calc(100vh / 2 - 30px);
    right: 9px;
    margin-left: -60px;
    width: 50px;
    height: 50px;
    z-index: 999;
  }
  .sticky-button {
    display: block;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    width: 50px;
    height: 50px;
    background-color: #047BBB;
    background-position: center 12px;
    background-size: 30px auto;
    background-repeat: no-repeat;
    margin-bottom: 12px;
    border-radius: 50%;
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .sticky-button .sticky-button-icon {
    display: block;
    width: 24px;
    margin: 9px auto;
  }
  .sticky-button.contact .sticky-button-icon {
    width: 28px;
    margin: 14px auto;
  }  
  .sticky-button .sticky-button-label {
    display: none;
  }
  .tab-contact img {
    display: block;
    border-radius: 4px;
    width: 50%;
    height: auto;
    margin-bottom: .5rem;
  }
  .owl-nav.main-nav {
    display: none;
  }
  .card-deck.owl-carousel.owl-theme {
    padding-left: 5px;
  }
  .container-fluid.container-calculator.primary-gradient {
    overflow-x:hidden;
  }
  #calculators h2 {
    margin-left: 0;
  }
  .overlay > .popup  {
    width: 100% !important;
  }
  .jumbotron.jumbotron-fluid.jumbotron-header .jumbotron-content {
    max-width: 100%;
    padding-right: 15px;
  }
  .jumbotron.jumbotron-fluid.jumbotron-header form .col-3 {
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
  }
  .container-bonus {
    width: auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .container-bonus .col-sm-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .tab-pane .col-sm-6.tab-contact .col-sm-6.text,
  .tab-pane .col-sm-6.tab-contact .col-sm-4 {
    text-align: center;
  }
  .tab-contact img {
    display: block;
    border-radius: 4px;
    width: 50%;
    height: auto;
    margin: 0 auto .5rem auto;
  }
  .tab-contact .mail,
  .tab-contact .phone {
    display: inline-block;
    padding-left: 2em;
    margin: 0 3em;
  }
  .container-teaser {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }
  .container-teaser .jumbotron > img {
    min-width: 250%;
    width: 250%;
    max-width: 250%;
    margin-left: -50%
  }
  .container-teaser .jumbotron-content.text-center {
    position: absolute!important;
    margin-top: 0 !important;
    top: calc(50% - 60px) !important;
    padding-left: 0 !important;
  }
  .btn-xlg {
    padding: 2rem 3.25rem;
  }
  footer .col-md-6 form.newsletter .col {
    padding-right: 15px !important;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 1em;
  }
  .container.container-legal-page-inner.pb-5 {
    padding-left: 0;
    padding-right: 0;
  }
  .container.container-product.container-us-product .card.col-sm-6 {
    flex: 0 0 calc(100%);
    max-width: calc(100%);
    position: static;
    padding-bottom: 0 !important;
  }
  .container.container-product.container-us-product .card.col-sm-6:first-child {
    margin-bottom: 2em;
  }
}/* @media (max-width: 575px)  */

/**
 *   Mobile: 480px
 */
@media (max-width: 480px) {
  .jumbotron.jumbotron-fluid.jumbotron-header {
    overflow-x: hidden !important;
  }  
  /*
  .jumbotron.jumbotron-fluid.jumbotron-header > img {
    margin: 0 -50%;
    min-width: 300%;
    max-width: 300%;
  }  
  */  
  footer .row.meta > * {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    padding: 5px 0;
  }
  .jumbotron.jumbotron-fluid.jumbotron-header .jumbotron-content .row.mt-5 {
    margin-top: 0 !important;
  }  
  .jumbotron.jumbotron-fluid.jumbotron-header .jumbotron-content {
    max-width: 100%;
    padding-right: 15px;
  }  
  .tab-contact .mail,
  .tab-contact .phone {
    margin: 0;
  }  
}/* @media (max-width: 480px)  */

/**
 *   Mobile: 460px
 */
@media (max-width: 460px) {
  #calculators .calc-result .card-body {
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
}/* @media (max-width: 460px)  */



